/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

import { useParams } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AppleIcon from "@mui/icons-material/Apple";
import useStyles from "./style";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Avatar as AvatarMui,
  Link,
  IconButton,
  styled,
} from "@mui/material";
import {
  handleUndefined,
  postDataRequestPublic,
} from "../../../../common/commonfunctions";
import {
  BusinessEventData,
  Businesseventbooking,
  Createcontactorganization,
} from "../../../../Api Mutation/Event";
import config from "../../../../config";
import { showNotification } from "../../../../components/Notification/ToastifyNotifications";
import { Entity } from "../../../../Api Mutation/EntityApi";
import { LoadingPage } from "../../../LoadingPage/LoadingPage";
import { EventStatus } from "../../../../common/const";
import Payment from "../../../Setting/PaymentConnection/Payment";
import FormGenerator from "../../InquiryForm/FormGenerator";
import { linkValidate } from "../../../../common/FormValidation";
import locationIcon from '../../../../images/locationIcon.svg';
import dateTimeIcon from '../../../../images/dateTimeIcon.svg';
import downloadIcon from '../../../../images/downloadIcon.svg';
import dollarIcon from '../../../../images/dollarIcon.svg';
import androidIcon from '../../../../images/androidIcon.svg';
import SocialShare from '../SocialShare'
import PoweredByLogo from "../../../../common/PoweredByLogo";
import BusinessFavicon from "../../../../common/BusinessFavicon";

const EventPublicURL = () => {
  const { id, locationid, businessid } = useParams();
  const classes = useStyles();
  const [dataLoading, setDataLoading] = useState(false);
  const [eventName, setEventName] = useState();
  const [locationMeeting, setLocationMeeting] = useState();
  const [eventbookingurl, setEventBookingUrl] = useState("");
  const [entity, setEntity] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phone, setPhone] = useState();
  const [whatsApp, setWhatsApp] = useState();
  const [fontColorCode, setFontColorCode] = useState('black');
  const [IOS, setIOS] = useState();
  const [android, setAndroid] = useState();
  const [favIcon, setFavicon] = useState(null);
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    moment(new Date()).add(30, "m").toDate()
  );
  const [eventDescription, setEventDescription] = useState();
  const [hostingName, setHostingName] = useState("");
  const [hostingImage, setHostingImage] = useState("");
  const [eventImage, setEventImage] = useState([]);
  const [document, setDocument] = useState([]);
  const [content, setContent] = useState([]);
  const [paymentId, setPaymentId] = useState([]);
  const [eventId, setEventId] = useState("");
  const [status, setStatus] = useState("");
  const [paidEvent, setPaidEvent] = useState();
  const [eventPrice, setEventPrice] = useState(0);
  const [contactData, setContactData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [isPayment, setIsPayment] = useState(false);
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const [updatedBy, setupdatedBy] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [submittedData, setData] = useState("");
  const [termDesc, setTermDesc] = useState();
  const [defaultCountryPhoneCode, setDefaultCountryPhoneCode] = useState('1');
  const [enableTerm, setEnableTerm] = useState(false);
  const [enableCaptcha, setEnableCaptcha] = useState(false);
  const [btnName, setBtnName] = useState("Subscribe Now");
  const [colorCode, setColorCode] = useState("#005eb2");
  const [backgroundColorCode, setBackgroundColorCode] = useState("transparent");
  const [foreColor, setForeColor] = useState("#000");
  const [btnPos, setBtnPos] = useState("right");
  const [radioPos, setRadioPos] = useState('vertical');
  const [checkBoxPos, setCheckBoxPos] = useState('vertical');
  const [btnThemeColor, setBtnThemeColor] = useState("#005eb2");
  const [btnTextColor, setBtnTextColor] = useState("#000");
  const [formWidth, setFormWidth] = useState('50');

  const getData = (eventid) => {
    if (eventid) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: BusinessEventData,
        variables: {
          eventid,
        },
      }).then((res) => {
        setEventId(res?.data?.businesseventdata?.eventid);
        setStatus(res?.data?.businesseventdata?.status);
        setEventName(res?.data?.businesseventdata?.name);
        setLocationMeeting(res?.data?.businesseventdata?.location);
        setEventDescription(res?.data?.businesseventdata?.description);
        setWhatsApp(res?.data?.businesseventdata?.isshowwhatsappicon);
        setIOS(res?.data?.businesseventdata?.isshowiosicon);
        setAndroid(res?.data?.businesseventdata?.isshowandroidicon);
        setPhone(res?.data?.businesseventdata?.isshowphoneicon);
        setFontColorCode(res?.data?.businesseventdata?.fieldtextcolor);
        setStartDate(res?.data?.businesseventdata?.eventstartdate);
        setEndDate(res?.data?.businesseventdata?.eventenddate);
        setHostingName(res?.data?.businesseventdata?.hostname);
        setHostingImage(res?.data?.businesseventdata?.hostimage);
        setEventImage(res?.data?.businesseventdata?.eventimage);
        setRadioPos(res?.data?.businesseventdata?.radioview);
        setCheckBoxPos(res?.data?.businesseventdata?.checkboxview);
        setContent(JSON.parse(res?.data?.businesseventdata?.formdata));
        setDocument(res?.data?.businesseventdata?.documents);
        setEventBookingUrl(res?.data?.businesseventdata?.eventbookingurl);
        setPaidEvent(res?.data?.businesseventdata?.ispaid);
        setFormWidth(res?.data?.businesseventdata?.formwidth);
        setEventPrice(res?.data?.businesseventdata?.eventprice);
        setupdatedBy(res?.data?.businesseventdata?.updatedby);
        setBtnName(res?.data?.businesseventdata?.calloutbuttontext);
        setColorCode(res?.data?.businesseventdata?.themecolor);
        setBackgroundColorCode((res?.data?.businesseventdata?.backgroundcolor === '#fff' || res?.data?.businesseventdata?.backgroundcolor === '#ffffff') ? 'transparent' : res?.data?.businesseventdata?.backgroundcolor);
        setForeColor(res?.data?.businesseventdata?.textcolor);
        if (res?.data?.businesseventdata?.btnthemecolor) {
          setBtnThemeColor(res?.data?.businesseventdata?.btnthemecolor);
          setBtnTextColor(res?.data?.businesseventdata?.btntextcolor);
        }
        setBtnPos(res?.data?.businesseventdata?.calloutbtnposition);
        setTermDesc(res?.data?.businesseventdata?.termdesc);
        setEnableTerm(res?.data?.businesseventdata?.termenable);
        setEnableCaptcha(res?.data?.businesseventdata?.captchaenable);
        setEnablePublicLogo(res?.data?.businesseventdata?.isshowfooterlogo);
        setDataLoading(false);
      });
    }
  };
  useEffect(() => {
    getData(id);
  }, [id]);

  const getContact = (cid) => {
    if (cid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
        query contact($contactid: ID!){
          contact(contactid:$contactid){
            contactid,primaryemail,firstname,lastname,contactavatar,phonenumber,lifecyclestage,locationid,
            linkedin,createdby,updatedby,jobid,ownerid,createdAt,updatedAt,istargetlist,ispininchat,organization{
              organizationid,name,orgwebsite
            }
      }}
      `,
        variables: {
          contactid: cid,
        },
      }).then((res) => {
        if (res?.data?.contact) {
          setContactData(res?.data?.contact);
        }
      });
    }
  };

  const getOrganization = (oid) => {
    if (oid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
              query organization($organizationid: ID!){
                organization(organizationid: $organizationid){
                      organizationid,name,locationid,orgphonenumber,orgwebsite,orgtwitter,orgfacebook,orgstatus,categories,employeesize,
                      yearsinbusiness,revenue,businessstartdate,typeofentity,orgmap,orglinkedin,orggmb,orgemail,orgyoutubeurl,
                      address{addressid,street1,street2,city,county,stateid,countryid,zip,country{countryid,name},state{stateid,name}},orgyelp,orgtripadvisor,
                      createdby,updatedby,phonecountryid,createdAt,updatedAt,orgattribute1,orgattribute2,orgattribute3,orgattribute4,
                  }
              }`,
        variables: {
          organizationid: oid,
        },
      }).then((res) => {
        if (res?.data?.organization) {
          setOrganizationData(res?.data?.organization);
        }
      });
    }
  };

  const onFormSubmit = (data) => {
    setDataLoading(true);
    setData(data);
    postDataRequestPublic(config.baseURLApi, {
      query: Createcontactorganization,
      variables: {
        responsedata: JSON.stringify(data),
        eventid: id,
        locationid,
        businessid,
      },
    }).then((res) => {
      const result = res?.data?.createcontactorganization;
      if (result) {
        if (result.contactid) {
          getContact(result.contactid);
        } else if (result.organizationid) {
          getOrganization(result.organizationid);
        }
        if (paidEvent) {
          showNotification("You need to do payment for event booking.", "info");
          setIsPayment(true);
        } else {
          postDataRequestPublic(config.baseURLApi, {
            query: Businesseventbooking,
            variables: {
              responsedata: JSON.stringify(data),
              eventid: id,
              locationid,
              businessid,
              contactid: result.contactid,
              organizationid: result.organizationid,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          }).then((response) => {
            const respo = response?.data?.businesseventbooking;
            if (respo?.message) {
              showNotification(respo.message, "success");
            } else {
              showNotification("Event booked successfully", "success");
              setIsSuccess(true);
              getData(eventId);
            }
            setDataLoading(false);
          });
        }
      }
      setDataLoading(false);
    });
  };

  const diff = moment(moment.utc(endDate).toDate())
    .local()
    .diff(moment(), "minute");

  const entityData = () => {
    postDataRequestPublic(config.baseURLApi, {
      query: Entity,
      variables: { entityid: locationid },
    }).then((res) => {
      if (res?.data?.entity) {
        setEntity(res?.data?.entity);
        setLocationBrandImage(res?.data?.entity?.logourl);
        setFavicon(res?.data?.entity?.favicon);
        if (res?.data?.entity?.defaultphonecountrycode) {
          setDefaultCountryPhoneCode(res?.data?.entity?.defaultphonecountrycode);
        }
      }
      setDataLoading(false);
    });
  };

  useEffect(() => {
    entityData();
  }, [locationid]);

  useEffect(() => {
    if (updatedBy !== "") {
      postDataRequestPublic(config.baseURLApi, {
        query: `query userprofilebyuser($userId:ID!) 
          {userprofilebyuser(userid: $userId) {
            phonenumber             
          }}`,
        variables: {
          userId: updatedBy,
        },
      }).then((result) => {
        if (result?.data?.userprofilebyuser?.phonenumber) {
          setPhoneNumber(result?.data?.userprofilebyuser?.phonenumber);
          setDataLoading(false);
        }
      });
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: {
          entityid: updatedBy,
        },
      }).then((result) => {
        if (result?.data?.entity?.phonenumber) {
          setPhoneNumber(result?.data?.entity?.phonenumber);
        }
        setDataLoading(false);
      });
    }
  }, [updatedBy]);

  useEffect(() => {
    setDataLoading(true);
    if (paymentId.length > 0) {
      postDataRequestPublic(config.baseURLApi, {
        query: Businesseventbooking,
        variables: {
          responsedata: JSON.stringify(submittedData),
          eventid: id,
          locationid,
          businessid,
          contactid: contactData?.contactid,
          organizationid: organizationData?.organizationid,
          paymentid: paymentId,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }).then((response) => {
        const respo = response?.data?.businesseventbooking;
        if (respo?.message) {
          showNotification(respo.message, "success");
        } else {
          showNotification("Event booked successfully", "success");
          setIsSuccess(true);
          setIsPayment(false);
          getData(eventId);
        }
        setDataLoading(false);
      });
    }
  }, [paymentId]);

  const StyledTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Roboto",
  }));
  const HostedTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: 20,
  }));

  const MoneyTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Roboto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "14px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "14px",
    },
  }));

  const HostTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
  }));

  const HostNameTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
  }));

  const ExpireTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 12,
  }));

  const CoverImg = styled("img")(({ theme }) => ({
    width: '80%',
    height: 'auto',
    aspectRatio: 'auto',
    objectFit: 'contain !important',
    marginTop: 23,
    [theme.breakpoints.down('md')]: {
      height: '455px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '230px',
    },
  }));

  const LongTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Roboto",
    fontWeight: 400,
    marginTop: 30,
    fontSize: "14px",
  }));

  const CustomeTypo = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    marginTop: 20,
    fontSize: 12,
  }));

  const NewBox = styled(Typography)(({ theme }) => ({
    justifyContent: "center",
    display: "block",
  }));

  const NestedBox = styled(Typography)(({ theme }) => ({
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  }));

  const AdvancedGrid = styled(Typography)(({ theme }) => ({
    marginTop: '30px',
    marginBottom: '30px'
  }));

  const NewImgBox = styled(Typography)(({ theme }) => ({
    marginTop: 3,
    background: '#ecebee',
    padding: '8px',
    paddingBottom: '2px',
    borderRadius: '22px',
  }));

  const StyledPaper = styled(Paper)(({ theme }) => ({
    background: 'transparent',
  }));
  const StyledDiv = styled("div")(({ theme }) => ({
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%',
    },
  }));

  const StyledPaperInfo = styled(Paper)(({ theme }) => ({
    width: `60% !important`,
    margin: 'auto',
    height: 'auto',
    padding: 37,
    justifyContent: 'center',
    background: backgroundColorCode,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      width: '100% !important',
      height: 'auto',
    },
    border: '1px solid lightgrey',
    boxShadow: 'none',
  }));

  return (
    <>
      {dataLoading ? <LoadingPage /> : <></>}
      <>
        <BusinessFavicon
          name={eventName}
          logo={favIcon || locationBrandImage}
        />
        <StyledPaper>
          <Grid container className={classes.container} style={{ padding: '20px 75px 20px 75px' }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {eventImage ? (
                  <CoverImg src={eventImage} className={classes.coverIMG} alt="eventimage" />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container>
                <Grid item xs={12} sm={9} md={9} lg={9}>
                  <CustomeTypo
                    variant="body2"
                    className={
                      status === EventStatus.Cancel
                        ? classes.errorlink
                        : diff <= 0
                          ? classes.errorlink
                          : classes.publiclink
                    }
                  >
                    {status === EventStatus.Cancel
                      ? "This Event has cancelled"
                      : diff <= 0
                        ? "This Event has finished"
                        : `${moment.utc(startDate, 'YYYY-MM-DD HH:mm:ss Z').tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('dddd, MMMM D ')}`}
                  </CustomeTypo>
                  <Typography className={classes.eventName} variant="h3">
                    <strong>{eventName}</strong>
                  </Typography>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LongTypo
                      variant="body2"
                      dangerouslySetInnerHTML={{ __html: eventDescription }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Grid container spacing={2} style={{ marginTop: 30, alignItems: 'center' }}>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      style={{ paddingLeft: 12 }}
                    >
                      <NewImgBox style={{ padding: '7px', background: 'transparent' }}>
                        <SocialShare event Url={entity?.subdomain
                          ? `https://${entity?.subdomain}${config.subDomainUrl}/ep/${eventId}/${locationid}/${businessid}`
                          : `${config.BixUrl}/ep/${eventId}/${locationid}/${businessid}` || eventbookingurl} >

                        </SocialShare>
                      </NewImgBox>


                    </Grid>
                    &nbsp;&nbsp;&nbsp;
                    {phoneNumber && phone === true ? (
                      <Grid item xs={2} sm={2} md={2} lg={2} className="ml-1">
                        <IconButton
                          className={classes.Button}
                          size="small"
                          href={`tel:${phoneNumber}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <NewImgBox>

                            <PhoneIcon
                              style={{ fontSize: 25, color: "#072cfe", paddingBottom: 3, padding: 2 }}
                            />
                          </NewImgBox>
                        </IconButton>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {phoneNumber && whatsApp === true ? (
                      <Grid item xs={2} sm={2} md={2} lg={2} className="ml-1">
                        <IconButton
                          className={classes.Button}
                          size="small"
                          href={`${`https://api.whatsapp.com/send?phone=${handleUndefined(
                            phoneNumber
                          )}`}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <NewImgBox>

                            <WhatsAppIcon
                              style={{ fontSize: 25, color: "#3cd4a0" }}
                            />
                          </NewImgBox>
                        </IconButton>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {phoneNumber && IOS === true ? (
                      <Grid item xs={2} sm={2} md={2} lg={2} style={{ marginTop: '1px', paddingLeft: '0px' }}>
                        <IconButton
                          className={classes.ButtonPhone}
                          size="small"
                          href={`sms:${phoneNumber}&amp;body=`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <NewImgBox>

                            <AppleIcon
                              style={{ fontSize: 25, color: "#607d8b" }}
                            />
                          </NewImgBox>
                        </IconButton>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {phoneNumber && android === true ? (
                      <Grid item xs={2} sm={2} md={2} lg={2} style={{ paddingLeft: 0 }}>
                        <IconButton
                          className={classes.ButtonPhone}
                          size="small"
                          href={`sms:${phoneNumber}?body=`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <NewImgBox style={{ paddingBottom: '10px', padding: '8px' }}>

                            <img alt="androidIcon" src={androidIcon} style={{ height: '24px', fontSize: 25, color: "#ffc260" }} />
                          </NewImgBox>

                        </IconButton>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Box className={classes.publichostingbox}>
                <Box>
                  <AvatarMui
                    className={classes.publichostingimg}
                    src={hostingImage || hostingName?.toUpperCase().charAt(0)}
                  >
                    <HostedTypo variant="h1">
                      {hostingImage || hostingName?.toUpperCase().charAt(0)}
                    </HostedTypo>
                  </AvatarMui>
                </Box>
                <Box className={classes.publichostingname}>
                  <HostTypo variant="body1">Hosted By</HostTypo>
                  <HostNameTypo variant="body2">
                    {hostingName?.charAt(0).toUpperCase() +
                      hostingName.slice(1)}
                  </HostNameTypo>
                </Box>
              </Box>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <AdvancedGrid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.grid}
                >
                  <Box className={classes.publichostingbox}>
                    <NewBox>
                      <Typography variant="h6">
                        <strong>Date and Time</strong>
                      </Typography>
                      <NestedBox>
                        <img alt="eventimage" className={
                          status === EventStatus.Cancel
                            ? classes.erroricons
                            : diff <= 0
                              ? classes.erroricons
                              : classes.publicicons
                        } src={dateTimeIcon} style={{ height: '24px' }} />

                        <Box className={classes.publichostingname}>
                          <ExpireTypo
                            variant="body2"
                            className={
                              status === EventStatus.Cancel
                                ? classes.errorlink
                                : diff <= 0
                                  ? classes.errorlink
                                  : classes.publiclink
                            }
                          >
                            {status === EventStatus.Cancel
                              ? "This Event has been cancelled"
                              : diff <= 0
                                ? "This Event has been finished"
                                : `${moment.utc(startDate, 'YYYY-MM-DD HH:mm:ss Z').tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('dddd, MMMM D · h:mm A z')} To ${moment.utc(endDate, 'YYYY-MM-DD HH:mm:ss Z').tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('h:mm A z')}`}
                          </ExpireTypo>
                        </Box>
                      </NestedBox>
                    </NewBox>
                  </Box>
                </AdvancedGrid>
                <AdvancedGrid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.grid}
                >
                  <NewBox className={classes.publichostingbox}>
                    <Typography variant="h6">
                      <strong>Location</strong>
                    </Typography>

                    <NestedBox className={classes.publichostingname}>
                      <Box>
                        <img alt="locationimage" className={classes.publicicons} src={locationIcon} style={{ height: '24px' }} />
                      </Box>
                      {diff <= 0 ? (
                        <ExpireTypo
                          variant="body2"
                          style={{ marginLeft: '10px' }}
                          className={classes.errorlink}
                        >
                          This Event has been finished
                        </ExpireTypo>
                      ) : status === EventStatus.Cancel ? (
                        <ExpireTypo
                          variant="body2"
                          style={{ marginLeft: '10px', marginTop: 10 }}
                          className={classes.errorlink}
                        >
                          This Event has been cancelled
                        </ExpireTypo>
                      ) : (
                        <>
                          {locationMeeting && linkValidate(locationMeeting) ? (
                            <Link href={locationMeeting} target="_blank">
                              <StyledTypo
                                variant="body2"
                                className={classes.meetingLink}
                              >
                                Event Location
                              </StyledTypo>
                            </Link>
                          ) : (
                            <ExpireTypo
                              variant="body2"
                              className={classes.meetingLink}
                              style={{ marginLeft: '15px' }}
                            >
                              Register for the event to get the details
                            </ExpireTypo>
                          )}
                        </>
                      )}
                    </NestedBox>
                  </NewBox>
                </AdvancedGrid>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {paidEvent ? <AdvancedGrid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.grid}
                  style={{ marginTop: 37 }}
                >
                  <NewBox>
                    <Typography variant="h6">
                      <strong>Price</strong>
                    </Typography>
                    <Grid container>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        className={classes.grid}
                      >
                        <Box className={classes.publichostingbox}>
                          <Box>
                            <img alt="locationimage" className={classes.publicicons} src={dollarIcon} style={{ height: '24px' }} />
                          </Box>
                          <Box>
                            <MoneyTypo
                              variant="body2"
                              className={classes.publiclink}
                            >
                              {eventPrice}
                            </MoneyTypo>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </NewBox>
                </AdvancedGrid> : ''}
                {document && typeof document === "string" ? (
                  <AdvancedGrid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.grid}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      lg={9}
                      className={classes.grid}
                    >
                      <NewBox>
                        <Typography variant="h6">
                          <strong>Download</strong>
                        </Typography>

                        <Box className={classes.publichostingbox}>
                          <Box>
                            <img alt="locationimage" className={classes.publicicons} src={downloadIcon} style={{ height: '24px' }} />
                          </Box>
                          <Box className={classes.publichostingnameMargin} style={{ marginBottom: 0 }}>
                            {document && typeof document === "string" && (
                              <Link href={document} target="_blank" download>
                                <StyledTypo
                                  variant="body2"
                                  className={classes.publiclink}
                                >
                                  {
                                    document?.split("/")[
                                    document?.split("/")?.length - 1
                                    ]
                                  }
                                </StyledTypo>
                              </Link>
                            )}
                          </Box>
                        </Box>
                      </NewBox>
                    </Grid>
                  </AdvancedGrid>
                ) : ''}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className={classes.iconWrapper}>
                {isPayment ? (
                  <><Grid item xs={12} sm={12} md={12} lg={12} className={classes.subcontainer}>
                    <div className={classes.paper}>
                      <Paper className={classes.cal} elevation={6}>
                        <Payment
                          contactData={contactData}
                          organizationData={organizationData}
                          amount={eventPrice}
                          userId={businessid}
                          businessId={businessid}
                          locationId={locationid}
                          setPaymentId={setPaymentId}
                          eventId={eventId}
                          event />
                      </Paper>
                    </div>
                  </Grid><Typography className={classes.horizontalLine} /></>

                ) : (
                  <>
                    <Typography className={classes.horizontalLine} />
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                      <StyledDiv>
                        <StyledPaperInfo elevation={12} className={classes.subcontainer}>
                          {diff <= 0 ? (
                            <StyledTypo variant="h6" className={classes.errormsg}>
                              This Event has been finished
                            </StyledTypo>
                          ) : status === EventStatus.Cancel ? (
                            <StyledTypo variant="h6" className={classes.errormsg}>
                              This Event has been cancelled
                            </StyledTypo>
                          ) : (
                            <>
                              {isSuccess ? (
                                <StyledTypo
                                  variant="h6"
                                  className={classes.successmsg}
                                >
                                  Thank you for registration for this event.
                                  Please check your email for more details.
                                </StyledTypo>
                              ) : (
                                <FormGenerator
                                  content={content}
                                  onFormSubmit={onFormSubmit}
                                  defaultCountryPhoneCode={defaultCountryPhoneCode}
                                  rows={12}
                                  btnName={btnName}
                                  formWidth={formWidth}
                                  radioPos={radioPos}
                                  checkBoxPos={checkBoxPos}
                                  setFormWidth={setFormWidth}
                                  fontColorCode={fontColorCode}
                                  colorCode={colorCode}
                                  backgroundColorCode={backgroundColorCode}
                                  foreColor={foreColor}
                                  btnPos={btnPos}
                                  btnThemeColor={btnThemeColor}
                                  btnTextColor={btnTextColor}
                                  termDesc={termDesc}
                                  enableTerm={enableTerm}
                                  enableCaptcha={enableCaptcha}
                                  eventType="single-page" />
                              )}
                              <center>
                                <PoweredByLogo enablePublicLogo={enablePublicLogo} id={locationid} />
                              </center>
                            </>
                          )}
                        </StyledPaperInfo>
                      </StyledDiv>
                    </Grid>
                    <Typography className={classes.horizontalLine} />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </StyledPaper>
        <Grid container className={classes.subcontainer}>
          <Grid item xs={12} sm={12} md={12} lg={6}></Grid>
        </Grid>
      </>
    </>
  );
};

export default EventPublicURL;
