export const Campaignrunsbyemailcampaign = `
query campaignrunsbyemailcampaign($emailcampaignid: ID!, $pagenumber: Int!, $pagesize: Int!, $isdelivered: Boolean, $isopened: Boolean, $isclicked: Boolean, $isunsubscribed: Boolean, $isbounced: Boolean, $iserror: Boolean) {
    campaignrunsbyemailcampaign(emailcampaignid: $emailcampaignid, pagenumber: $pagenumber, pagesize: $pagesize, isdelivered: $isdelivered, isopened: $isopened, isclicked: $isclicked, isunsubscribed: $isunsubscribed, isbounced: $isbounced, iserror: $iserror) {
      count,
      rows {
        campaignrunid
        sendto
        sgemailid
        opened
        status
        openeddate
        delivereddate
        clickeddate
        bounceddate
        unsubscribeddate
        scheduleat
        senttime
        errormessage
        customerid
        locationid
        createdby
        updatedby
        emailcampaignid
        campaignrunstatus
        campaign {
          campaignid
          name
          subject
          communicationtypeid
          body
          scheduledatetime
        }
        serviceproviderid
        serviceprovider
        threadid
        openeddata
        clickeddata
        opencount
        clickcount
        contact {
          contactid
          primaryemail
          firstname
          lastname
          phonenumber
          locationid
          jobid
        }
      }
    }
  }`;

export const Emailcampaignclickreportbyid = `
  query emailcampaignclickreportbyid($emailcampaignid: ID!) {
    emailcampaignclickreportbyid(emailcampaignid: $emailcampaignid) {
      url
      totalcount
      uniquecount
      customerid
      datetime
      contact {
        contactid
        primaryemail
        firstname
        lastname
        phonenumber
        jobid
        locationid
      }
    }
  }`;

export const Campaignrun = `
query campaignrun($campaignrunid: ID!) {
  campaignrun(campaignrunid: $campaignrunid) {
    campaignrunid
    sendto
    sgemailid
    opened
    status
    openeddate
    delivereddate
    clickeddate
    bounceddate
    unsubscribeddate
    scheduleat
    senttime
    errormessage
    customerid
    locationid
    createdby
    updatedby
    emailcampaignid
    campaignrunstatus
    campaign {
      campaignid
      name
      communicationtypeid
      subject
      body
      customers
      senditnow
      scheduledatetime
      locationid
      createdby
      updatedby
      status
      reviewtypeid
      createdAt
      updatedAt
      campaigntype
      createdUser {
        userid
        roleid
        firstname
        lastname
        phonenumber
        email
        role
        lastlogin
        deleted
        usertype
        businessid
        locationid
        defaultcontactview
      }
    }
    serviceproviderid
    serviceprovider
    threadid
    openeddata
    clickeddata
    opencount
    clickcount
    emailmarketing {
      emailcampaignid
      name
      fromname
      fromemail
      subject
      emailtemplate
      customers
      senditnow
      scheduledatetime
      locationid
      createdby
      updatedby
      status
      createdAt
      updatedAt
      templatedesign
      totaldelivered
      totalopened
      totalclicked
      totalbounced
      totalunsubscribed
      serviceproviderid
      serviceprovider
    }
  }
}`;

export const Emailcampaignurlreportbyid = `
query emailcampaignurlreportbyid($emailcampaignid: ID!, $url: String!) {
    emailcampaignurlreportbyid(emailcampaignid: $emailcampaignid, url: $url) {
      url
      totalcount
      uniquecount
      customerid
      datetime
      contact {
        contactid
        primaryemail
        firstname
        lastname
        phonenumber
      }
    }
  }`;

export const UniqueEmailCampaignUrlReportById = `
query uniqueEmailcampaignurlreportbyid($emailcampaignid: ID!, $url: String!) {
  uniqueEmailcampaignurlreportbyid(emailcampaignid: $emailcampaignid, url: $url) {
      url
      totalcount
      uniquecount
      customerid
      datetime
      contact {
        contactid
        primaryemail
        firstname
        lastname
        phonenumber
      }
    }
  }`;

export const ContactWithReport = `
  query contactWithReport($contactid: ID!){
    contactWithReport(contactid: $contactid){
      contactid,primaryemail,contactavatar,firstname,lastname,lifecyclestage,
      locationid,createdby,updatedby,ownerid,createdAt,updatedAt,phonenumber
     status,totalunread,todayactivity,totalactivity,todaytask,lastfivedaystask,pasttask,
     opentask,opennote,totalunread,organizationid
    }
}`;

export const CampaignrunsbycampaignReport = `
query campaignrunsbycampaign($campaignid: ID!, $pagenumber: Int!, $pagesize: Int!) {
  campaignrunsbycampaign(campaignid: $campaignid, pagenumber: $pagenumber, pagesize: $pagesize) {
    count
    rows {
      campaignrunid
      sendto
      sgemailid
      opened
      status
      openeddate
      delivereddate
      clickeddate
      bounceddate
      unsubscribeddate
      scheduleat
      senttime
      errormessage
      customerid
      locationid
      createdby
      updatedby
      emailcampaignid
      campaignrunstatus
      campaign {
        campaignid
        name
        communicationtypeid
        subject
        body
        customers
        senditnow
        scheduledatetime
        locationid
        createdby
        updatedby
        status
        reviewtypeid
        createdAt
        updatedAt
        campaigntype
        createdUser {
          userid
          roleid
          firstname
          lastname
          phonenumber
          email
          role
          lastlogin
          deleted
          usertype
          businessid
          locationid
          defaultcontactview
        }
      }
      serviceproviderid
      serviceprovider
      threadid
      openeddata
      clickeddata
      opencount
      clickcount
      contact {
        contactid
        primaryemail
        firstname
        lastname
        phonenumber
        locationid
        ownerid
      }
      emailmarketing {
        emailcampaignid
        name
        fromname
        fromemail
        subject
        emailtemplate
        customers
        senditnow
        scheduledatetime
        locationid
        createdby
        updatedby
        status
        createdAt
        updatedAt
        templatedesign
        totaldelivered
        totalopened
        totalclicked
        totalbounced
        totalunsubscribed
        serviceproviderid
        serviceprovider
      }
    }
  }
}`;

export const Campaignrunsbycampaign = `query Campaignrunsbyemailcampaign($emailcampaignid: ID!, $pagenumber: Int!, $pagesize: Int!, $isdelivered: Boolean, $isopened: Boolean, $isclicked: Boolean, $isbounced: Boolean, $isunsubscribed: Boolean, $iserror: Boolean) {
    campaignrunsbyemailcampaign(emailcampaignid: $emailcampaignid, pagenumber: $pagenumber, pagesize: $pagesize, isdelivered: $isdelivered, isopened: $isopened, isclicked: $isclicked, isbounced: $isbounced, isunsubscribed: $isunsubscribed, iserror: $iserror) {
      count
      rows {
        campaignrunid
        sendto
        sgemailid
        opened
        status
        openeddate
        delivereddate
        clickeddate
        bounceddate
        unsubscribeddate
        scheduleat
        senttime
        errormessage
        customerid
        locationid
        createdby
        updatedby
        emailcampaignid
        campaignrunstatus
        campaign {
          campaignid
          name
          communicationtypeid
          subject
          body
          customers
          senditnow
          scheduledatetime
          locationid
          createdby
          updatedby
          status
          reviewtypeid
          createdAt
          updatedAt
          campaigntype
          createdUser {
            userid
            roleid
            firstname
            lastname
            phonenumber
            email
            role
            lastlogin
            deleted
            usertype
            businessid
            locationid
            defaultcontactview
          }
        }
        serviceproviderid
        serviceprovider
        threadid
        openeddata
        clickeddata
        opencount
        clickcount
        contact {
          contactid
          primaryemail
          firstname
          lastname
          phonenumber
        }
      }
    }
  }`;

export const GetEmailCampaignReportData = `query getEmailCampaignReportData($emailcampaignid:ID!){
                getEmailCampaignReportData(emailcampaignid:$emailcampaignid){
                communicationtypename 
                reviewtypename
                totalerror
                totalsuccess
                totalsentemail
                total
                customerid
                errorpercentage
                successpercentage
                lastopeneddate
                lastclickeddate
                scheduleat
                totalclicked
                totaldelivered
                totalbounced
                totalopened
                totalunsubscribed
                campaignid
                name
                communicationtypeid
                subject
                body
                fromemail
                fromname
                serviceproviderid
                serviceprovider
                threadid
                senditnow
                scheduledatetime
                locationid
                createdby
                updatedby
                status
                reviewtypeid
                createdAt
                updatedAt
                graphdata
                }}`;

export const GetCampaignReportData = `query getCampaignReportData($campaignid:ID!){
    getCampaignReportData(campaignid:$campaignid){
    communicationtypename 
    reviewtypename
    totalerror
    totalsuccess
    totalsentemail
    total
    errorpercentage
    successpercentage
    lastopeneddate
    lastclickeddate
    scheduleat
    totalclicked
    totaldelivered
    totalbounced
    totalopened
    totalunsubscribed
    campaignid
    name
    communicationtypeid
    subject
    body
    customerid
    senditnow
    scheduledatetime
    locationid
    createdby
    updatedby
    status
    reviewtypeid
    createdAt
    updatedAt
    graphdata
    }}`;

export const ContentMarketing = `query contentMarketing($contentmarketingnid: ID!) {
  contentMarketing(contentmarketingnid: $contentmarketingnid) {
    contentmarketingnid
    name
    category
    author
    contenttemplate
    templatedesign
    locationid
    status
    isfree
    createdby
    updatedby
    createdAt
    updatedAt
    openeddata
    clickeddata
    sharecount
    shorturl
  }
}`;

export const ContentMarketingUrlReportByid = `query contentMarketingUrlReportByid($contentmarketingnid: ID!) {
      contentMarketingUrlReportByid(contentmarketingnid: $contentmarketingnid) {
        count
        url
      }
    }`;

export const EditContentMarketing = `
    mutation 
    editContentMarketing(
      $contentmarketingnid: ID!
      $name: String
      $category: [String]
      $author: String
      $contenttemplate: String
      $templatedesign: String
      $locationid: ID
      $status: Int
      $isfree: Boolean
      $updatedby: ID!
      ){
        editContentMarketing(
          contentmarketingnid :$contentmarketingnid
          name:$name
          category:$category
          author:$author
          contenttemplate:$contenttemplate
          templatedesign:$templatedesign
          locationid:$locationid
          status:$status
          isfree:$isfree
          updatedby:$updatedby
          )
      }`;

export const CreateContentMarketing = `
      mutation 
      createContentMarketing(
        $name: String
        $category: [String]
        $author: String
        $contenttemplate: String
        $templatedesign: String
        $locationid: ID!
        $status: Int
        $createdby: ID!
        $isfree: Boolean
         ){
          createContentMarketing( 
            name:$name
            category:$category
            author:$author
            isfree:$isfree
            contenttemplate:$contenttemplate
            templatedesign:$templatedesign
            locationid:$locationid
            status:$status
            createdby:$createdby
          ){
            contentmarketingnid
            name
            category
            author
            isfree
            contenttemplate
            templatedesign
            locationid
            status
            shorturl
            createdby
            updatedby
            createdAt
            updatedAt
          }
         }`;
export const CreateContentTemplate = `
         mutation
         createContentTemplate(
           $contenthtml: String
           $contentjson: String
           $name: String
           $usagetype: Int
           $industrytype: Int
           $type: Int
           $locationid: ID!
           $ownerid: ID
           $createdby: ID!
           $ispublic: Boolean
             ){
               createContentTemplate(
                 contenthtml:$contenthtml
                 contentjson:$contentjson
                 name:$name
                 usagetype:$usagetype
                 industrytype:$industrytype
                 type:$type
                 locationid:$locationid
                 ownerid:$ownerid
                 createdby:$createdby
                 ispublic:$ispublic
                   ){
                     contenttemplateid
                     contenthtml
                     contentjson
                     name
                     usagetype
                     industrytype
                     type
                     locationid
                     ownerid
                     ispublic
                     previewimage
                     deleted
                     createdby
                     updatedby
                   }
                  }`;

export const ContentMarketingbyshorturl = `
query contentMarketingbyshorturl($shorturl: String!, $locationid: ID!) {
  contentMarketingbyshorturl(shorturl: $shorturl, locationid: $locationid) {
    contentmarketingnid,
    contenttemplate,
    isshowfooterlogo,
  }
}`;

export const ReplicateEmailCampaign = `
mutation replicateEmailCampaign($emailcampaignid: ID!) {
  replicateEmailCampaign(emailcampaignid: $emailcampaignid) {
    emailcampaignid
    name
    fromname
    fromemail
    subject
    emailtemplate
    customers
    senditnow
    scheduledatetime
    locationid
    createdby
    updatedby
    status
    createdAt
    updatedAt
    templatedesign
    totaldelivered
    totalopened
    totalclicked
    totalbounced
    totalunsubscribed
    serviceproviderid
    serviceprovider
  }
}`;

export const ReplicateSmsCampaign = `
mutation replicateSmsCampaign($smscampaignid: ID!) {
  replicateSmsCampaign(smscampaignid: $smscampaignid) {
    smscampaignid
    name
    fromnumber
    fromname
    customers
    scheduledatetime
    smstext
    senditnow
    sendtime
    delevertime
    mmsurl
    locationid
    createdby
    updatedby
    status
    createdAt
    updatedAt
  }
}`;

export const ReplicateContentMarketing = `
mutation replicateContentMarketing($contentmarketingnid: ID!) {
  replicateContentMarketing(contentmarketingnid: $contentmarketingnid) {
    contentmarketingnid
    name
    category
    author
    contenttemplate
    templatedesign
    locationid
    status
    isfree
    createdby
    updatedby
    createdAt
    updatedAt
    openeddata
    clickeddata
    sharecount
    shorturl
    businessid
  }
}
`;

export const EmailcampaignSenders = `query emailcampaignsenders($locationid: ID!) {
  emailcampaignsenders(locationid: $locationid)
}`;

export const EmailCampaignsByContactId = `
query emailCampaignsByContactid($contactid: ID){
    emailCampaignsByContactid(contactid:$contactid){
        emailcampaignid,name,createdby,subject,scheduledatetime,openeddate,
        delivereddate,unsubscribeddate,bounceddate,errormessage,createdAt,sendto
 }}`;

export const CampaignsWithReviewByContact = `
query campaignsWithReviewByContact($contactid: ID){
    campaignsWithReviewByContact(contactid:$contactid){
        campaignid
        name
        communicationtypeid
        subject
        body
        customers
        senditnow
        scheduledatetime
        locationid
        createdby
        updatedby
        status
        reviewtypeid
        createdAt
        updatedAt
        campaigntype
        reviewid
        placeid
        providedby
        reviewdatetime
        review
        title
        reviewer
        displayinwidget
        reviewaction
        reviewstatusbyreplied
        rating
        replied
        reviewreplyid
        sourcereviewid
        customerid
        sourceid
        sharedcount
        likecount
        reviewcreatedate
        reviewupdatedat
        reviewstatus
        createdUser{firstname, lastname, email}
 }}`;

export const SendQuickReviewEmailSMS = `mutation
             sendQuickReviewEmailSMS(
                     $locationid: ID!,
                     $businessid: ID,
                     $customername: String!,
                     $customerid: ID!,
                     $isemail: Boolean,
                     $email: String!
                     $reviewTypeId: ID,
                     $createdby:ID!
                   ){
                     sendQuickReviewEmailSMS(
                         locationid: $locationid,
                         businessid: $businessid,
                         customername: $customername,
                         customerid: $customerid,
                         isemail: $isemail,
                         email: $email,
                         reviewtypeid : $reviewTypeId
                         createdby:$createdby
                       )
                   }`;

export const EditSmsCampaign = `mutation editSmsCampaign($smscampaignid: ID!, $updatedby: ID!, $name: String, $fromnumber: String, $fromname: String, $bixpandnumber: Boolean, $documents: [Upload], $customers: String, $senditnow: Boolean, $scheduledatetime: String, $smstext: String, $sendtime: String, $delevertime: String, $locationid: ID, $createdby: ID, $status: Int) {
  editSmsCampaign(smscampaignid: $smscampaignid, updatedby: $updatedby, name: $name, fromnumber: $fromnumber, fromname: $fromname, bixpandnumber: $bixpandnumber, documents: $documents, customers: $customers, senditnow: $senditnow, scheduledatetime: $scheduledatetime, smstext: $smstext, sendtime: $sendtime, delevertime: $delevertime, locationid: $locationid, createdby: $createdby, status: $status)
}`;
export const sendSms = `
mutation
  sendSms(
    $text: String!,
    $number: String!,
    $fullurl: String!,
  ){
    sendSms(
      text:$text,
      number:$number,
      fullurl:$fullurl,
    )
  }`;

export const CreateEmailTemplate = `
mutation
  createEmailTemplate(
      $emailhtml: String
      $emailjson: String
      $name: String
      $locationid: ID!
      $ownerid: ID
      $createdby: ID!
    ){
      createEmailTemplate(
          emailhtml:$emailhtml
          emailjson:$emailjson
          name:$name
          locationid:$locationid
          ownerid:$ownerid
          createdby:$createdby
          ){
            emailtemplateid
            emailhtml
            emailjson
            name
            locationid
            ownerid
            createdby
          }
         }`;

export const FileUpload = `
mutation
    fileUpload(
        $fileName: String!,
        $data: String!,
        $mimeType: String!
         ){
            fileUpload(
             fileName:$fileName,
             data:$data,
             mimeType:$mimeType
             )
         }`;

export const SendTestEmailMarketing = `
mutation
sendtestemailmarketing(
  $subject: String!
  $emailtemplate: String!
  $email: String!
  $fromemail: String
  $fromname: String
){
  sendtestemailmarketing(
    subject:$subject
    emailtemplate:$emailtemplate
    email:$email
    fromemail:$fromemail
    fromname:$fromname
    )
}`;

export const EmailTemplateByFilters = `query emailtemplatebyfilters($locationid: ID, $usagetype: [Int!], $industrytype: [Int!], $type: [Int!], $ispublic: Boolean) {
  emailtemplatebyfilters(locationid: $locationid, usagetype: $usagetype, industrytype: $industrytype, type: $type, ispublic: $ispublic) {
    emailtemplateid
    emailhtml
    emailjson
    name
    usagetype
    industrytype
    type
    locationid
    ownerid
    ispublic
    previewimage
    deleted
    createdby
    updatedby
  }
}`;

export const LookupdataByName = `query lookupdatabyname($name:String!)
{lookupdatabyname(name:$name){lookupid,name,key,value,parentvalue}}`;

export const Emailtemplate = `query Emailtemplate($emailtemplateid: ID!) {
  emailtemplate(emailtemplateid: $emailtemplateid) {
    emailtemplateid
    emailhtml
    emailjson
    name
    usagetype
    industrytype
    type
    locationid
    ownerid
    ispublic
    previewimage
    deleted
    createdby
    updatedby
  }
}`;
export const DeleteEmailTemplate = `
      deleteEmailTemplate(
          $emailtemplateid: ID!
      ){
        deleteEmailTemplate(
          emailtemplateid: $emailtemplateid
          )
      }`;

      export const AddActivityContentMarketing = `
      mutation addActivityContentMarketing($contentmarketingnid: ID!, $openeddata: String, $clickeddata: String) {
        addActivityContentMarketing(contentmarketingnid: $contentmarketingnid, openeddata: $openeddata, clickeddata: $clickeddata)
      }`;


      export const ContentMarketingbylocation = `query contentMarketingbylocation($locationid: ID!, $pageNumber: Int!, $pageSize: Int!, $status: Int, $startdate: String, $enddate: String, $searchtext: String, $ispublish: Boolean) {
        contentMarketingbylocation(locationid: $locationid, pageNumber: $pageNumber, pageSize: $pageSize, status: $status, startdate: $startdate, enddate: $enddate, searchtext: $searchtext, ispublish: $ispublish) {
          count
          rows {
            contentmarketingnid
            name
            category
            author
            contenttemplate
            templatedesign
            locationid
            status
            isfree
            createdby
            updatedby
            createdAt
            updatedAt
            openeddata
            clickeddata
            sharecount
            shorturl
            businessid
            listpageimage
            ispublish
          }
        }
      }`;

      export const ContentMarketingSettingsByLocationId = `query contentMarketingSettingsByLocationId($locationid: ID!) {
        contentMarketingSettingsByLocationId(locationid: $locationid) {
          contentmarketingsettingsid
          pagetitle
          subtitle
          contentposition
          isshowsubscribeform
          lifecyclestage
          subscriptiontitle
          ownerid
          status
          businessid
          locationid
          createdby
          updatedby
          isshowfooterlogo
        }
      }`;
